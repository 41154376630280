<template>
  <v-container fluid>
    <v-widget title="Transportador" class="pb-0" style="height: calc(100vh - 136px); border-radius: 4px">
      <template v-slot:widget-content>
        <v-form v-model="valid" ref="formTransportador" lazy-validation>
          <v-container grid-list-md fluid class="pa-0" style="overflow: hidden auto; box-sizing: border-box; height: calc(100vh - 268px)">
            <v-row dense class="pt-1">
              <v-col cols="12" sm="4">
                <v-text-field
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  label="CNPJ / CPF"
                  v-model="transportador.cnpjCpf"
                  v-mask="['###.###.###-##', '##.###.###/####-##']"
                  :rules="validarCnpjCpf"
                  required
                  ref="cnpjCpf"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Inscrição Estadual / RG" v-model="transportador.rgIe" :rules="validarIE">
                  <template v-slot:append>
                    <v-tooltip bottom style="cursor: pointer">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon :color="transportador.isento ? 'primary' : 'black'" @click="setIsento" v-bind="attrs" v-on="on" style="margin-top: -8px">
                          <v-icon>mdi-alpha-i-box</v-icon>
                        </v-btn>
                      </template>
                      <span>ISENTO</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Código ANTT" v-model="transportador.codigoAntt" v-input-upper-case />
              </v-col>

              <v-col cols="12" sm="12">
                <v-text-field placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="Razão Social / Nome" v-model="transportador.razaoSocialNome" :rules="validadarRazaoSocialNome" required v-input-upper-case />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Endereço</v-toolbar-title>
            </v-toolbar>

            <v-row dense class="pt-4">
              <v-col cols="12" sm="4">
                <campo-cep
                  v-mask="'#####-###'"
                  label="CEP"
                  ref="cep"
                  buscarcep
                  @resultado="setEndereco"
                  v-model="transportador.cep"
                  append-icon="map"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.logradouro.focus()"
                />
              </v-col>

              <v-col cols="12" sm="8">
                <v-text-field
                  label="Logradouro"
                  ref="logradouro"
                  v-model="transportador.logradouro"
                  :rules="validarLogradouro"
                  required
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.numero.focus()"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="2">
                <v-text-field
                  label="Número"
                  ref="numero"
                  v-model="transportador.numero"
                  :rules="validarNumero"
                  required
                  data-cy="numero"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.complemento.focus()"
                />
              </v-col>

              <v-col cols="12" sm="5">
                <v-text-field label="Complemento" ref="complemento" v-model="transportador.complemento" data-cy="complemento" placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" @keypress.enter="$refs.bairro.focus()" />
              </v-col>

              <v-col cols="12" sm="5">
                <v-text-field
                  label="Bairro"
                  ref="bairro"
                  v-model="transportador.bairro"
                  :rules="validarBairro"
                  required
                  data-cy="bairro"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.estado.focus()"
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete label="País" :rules="validarPais" v-model="transportador.codigoPais" :items="paises" placeholder=" " autocomplete="off" role="presentation" required persistent-placeholder outlined @change="setPais" />
              </v-col>

              <v-col cols="12" sm="4">
                <v-autocomplete
                  label="Estado"
                  :rules="validarEstado"
                  ref="estado"
                  v-model="transportador.codigoEstado"
                  :items="estados"
                  placeholder=" "
                  autocomplete="off"
                  role="presentation"
                  required
                  persistent-placeholder
                  outlined
                  @change="setEstado"
                  @blur="validarInscricaoEstadual"
                />
              </v-col>

              <v-col cols="12" sm="5">
                <v-autocomplete
                  label="Cidade"
                  ref="cidade"
                  v-model="transportador.codigoCidade"
                  @change="setCidade"
                  :items="cidadesEstado"
                  :rules="validarCidade"
                  :disabled="cidadesEstado.length === 0"
                  placeholder=" "
                  persistent-placeholder
                  outlined
                  autocomplete="off"
                  role="presentation"
                  @keypress.enter="$refs.telefone.focus()"
                />
              </v-col>
            </v-row>

            <v-toolbar flat dark color="secondary" dense style="border-radius: 4px 4px 0px 0px">
              <v-toolbar-title>Veículos</v-toolbar-title>
              <v-spacer />
              <v-btn depressed color="primary darken-1" @click="novoVeiculo">Novo</v-btn>
            </v-toolbar>

            <v-row dense class="pt-4 pb-2">
              <v-col v-for="(veiculo, i) in transportador.veiculos" :key="'r-' + i" cols="12" sm="6">
                <v-row dense>
                  <v-col cols="8">
                    <v-text-field
                      placeholder=" "
                      persistent-placeholder
                      outlined
                      autocomplete="off"
                      role="presentation"
                      label="Placa"
                      v-model="veiculo.placa"
                      v-mask="'AAA-#X##'"
                      required
                      hide-details
                      @input="(value) => (placa = value.toUpperCase())"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete placeholder=" " persistent-placeholder outlined autocomplete="off" role="presentation" label="UF" v-model="veiculo.uf" :items="estados" hide-details></v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="pa-5" v-if="transportador.veiculos.length === 0">
              <v-label>Não há veículos</v-label>
            </v-row>
          </v-container>

          <v-divider />

          <v-container fluid class="pa-0 pt-4 box shadow" style="text-align: end">
            <v-spacer></v-spacer>
            <v-btn depressed class="mr-2" @click="cancelar">Cancelar</v-btn>
            <v-btn depressed color="primary" :loading="carregando" @click="salvar" :disabled="!valid" data-cy="salvar">Salvar</v-btn>
          </v-container>
        </v-form>
      </template>
    </v-widget>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import constantes from '@/util/constantes';
import validador from '@/util/validador';

export default {
  data: () => ({
    validarCnpjCpf: [(v) => (v && v.length === 14 ? validador.cpf(v) : v.length === 18 ? validador.cnpj(v) : false) || 'CNPJ / CPF Inválido'],
    validarIE: [(v) => !!v || 'Inscrição Obrigatória'],
    validadarRazaoSocialNome: [(v) => !!v || 'Razão/Nome Obrigatório'],
    validarCep: [(v) => !!v || 'CEP Obrigatório'],
    validarLogradouro: [(v) => !!v || 'Logradouro Obrigatório'],
    validarNumero: [(v) => !!v || 'Número Obrigatório'],
    validarBairro: [(v) => !!v || 'Bairro Obrigatório'],
    validarCidade: [(v) => !!v || 'Cidade Obrigatória'],
    validarEstado: [(v) => !!v || 'Estado obrigatório'],
    validarPais: [(v) => !!v || 'País Obrigatório'],
    constantes: constantes,

    valid: false,
  }),

  computed: {
    ...mapState('transportador', {
      transportador: 'transportador',
      estado: 'estado',
      carregando: 'carregando',
    }),

    ...mapGetters('cidadeEstado', {
      estados: 'estados',
      cidadesEstado: 'cidadesEstado',
      paises: 'paises',
    }),

    isSm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  created() {
    try {
      this.loading = true;
      const estaEditando = this.$route.path.indexOf('editar') > -1 ? true : false;
      if (estaEditando && (!this.transportador._id || this.transportador._id === '')) {
        this.$store.commit('setMensagemErro', 'Selecione um transportador para editar');
        this.$router.push({ name: 'transportadores' });
        return;
      } else if (estaEditando && this.transportador.codigoEstado) {
        this.$store.commit('cidadeEstado/setState', { estado: this.transportador.codigoEstado });
      }
    } catch (error) {
      this.$store.commit('setMensagemErro', error);
    } finally {
      this.loading = false;
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$refs.cnpjCpf.focus();
      this.$refs.formTransportador.validate();
    });
  },

  methods: {
    validarInscricaoEstadual() {
      if (this.transportador.cnpjCpf.length < 18) return true;
      if (this.transportador.rgIe && this.transportador.estado) {
        return validador.inscricaoEstadual(`${this.transportador.rgIe}-${this.transportador.estado}`);
      }
      return this.transportador.rgIe === 'ISENTO' || false;
    },

    setIsento() {
      if (this.transportador.rgIe !== 'ISENTO') {
        this.transportador.rgIe = 'ISENTO';
      } else {
        this.transportador.rgIe = '';
      }
    },

    setPais() {
      this.$store.commit('cidadeEstado/setState', { pais: this.transportador.codigoPais });
    },

    setEstado() {
      this.$store.commit('cidadeEstado/setState', { estado: this.transportador.codigoEstado });
      this.$store.commit('transportador/setState', { transportador: { ...this.transportador, estado: this.$store.state.cidadeEstado.estados.find((estado) => estado.id === this.transportador.codigoEstado).nome } });
    },

    setCidade() {
      this.$store.commit('transportador/setState', { transportador: { ...this.transportador, cidade: this.$store.state.cidadeEstado.cidades.find((cidade) => parseInt(cidade.codigo_cidade, 10) === this.transportador.codigoCidade).cidade } });
    },

    novoVeiculo() {
      const veiculos = this.transportador.veiculos;
      if (!veiculos.find((veiculo) => !veiculo.placa.trim() && !veiculo.uf)) {
        veiculos.push({ placa: '', uf: null });
        this.$store.commit('transportador/setState', { transportador: { ...this.transportador, veiculos } });
      }
    },

    setEndereco(endereco) {
      this.transportador.bairro = endereco.bairro;
      this.transportador.complemento = endereco.complemento;
      this.transportador.codigoCidade = parseInt(endereco.ibge, 10);
      this.transportador.logradouro = endereco.logradouro;
      const estado = this.$store.state.cidadeEstado.estados.find((estado) => estado.sigla === endereco.uf);
      const cidade = this.$store.state.cidadeEstado.cidades.find((cidade) => cidade.estado === endereco.uf && cidade.codigo_cidade === endereco.ibge);
      this.transportador.codigoEstado = estado.id;
      this.transportador.estado = estado.nome;
      this.transportador.cidade = cidade.cidade;
      this.$store.commit('cidadeEstado/setState', { estado: estado.id });
    },

    async salvar() {
      if (this.$refs.formTransportador.validate() && this.validarInscricaoEstadual()) {
        if (this.transportador._id) {
          const result = await this.$store.dispatch('transportador/editar', this.foto);
          if (!result) return;
        } else {
          const result = await this.$store.dispatch('transportador/adicionar', this.foto);
          if (!result) return;
        }

        if (this.float) {
          this.$emit('fechar');
        } else {
          this.$router.push({ name: 'transportadores' });
        }
      }
    },

    cancelar() {
      if (this.float) {
        this.$emit('fechar');
      } else {
        this.$router.push({ name: 'transportadores' });
      }
    },
  },
};
</script>
